<template>
    <Teleport to="body">
      <div v-if="isModalOpen">
        <div class="modal-overlay" @click="confirmCloseModal"></div>
  
          <div class="modal" @click.stop>
            <div class="modal-content">
              <button class="modal-close" @click="confirmCloseModal">×</button>
              <iframe :src="url" id="ticket_frame" style="border: 0px; height: 100%; width: 100%"></iframe>
            </div>
          </div>
 
          <div v-if="showConfirm" class="confirm-overlay">
            <div class="confirm-modal">
              <div class="confirm-content">
                <p>Вы уверены, что хотите закрыть окно?</p>
                <button class="confirm-close" @click="closeModal">Закрыть</button>
                <button class="confirm-stay" @click="showConfirm = false">Остаться</button>
              </div>
            </div>
          </div>
      </div>
    </Teleport>
  </template>
  
  
  <script setup lang="ts">
  import { isModalOpen, modalUrl, openWidget } from '~/composables/useWidget';
  import { ref, watch, onMounted, defineProps, defineEmits } from 'vue';
  
  const props = defineProps<{ url: string; isOpen: boolean }>();
  const emit = defineEmits<{ (event: 'update:isOpen', value: boolean): void }>();
  
  const showConfirm = ref(false);
  
  watch(isModalOpen, (newVal) => {
    emit('update:isOpen', newVal);
  }); 
  
  const closeModal = () => {
    emit('update:isOpen', false);
    showConfirm.value = false;
  };
  
  const confirmCloseModal = () => {
    showConfirm.value = true;
  };
  
  onMounted(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') confirmCloseModal();
    });
  });
  </script>

<style scoped lang="postcss" src="./style.css" />