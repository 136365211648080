<template>
  <div id="tabs-container" ref="tabContainer" class="a-tabs">
    <div class="a-tabs__tabs">
      <div class="a-tabs__wrapper">
        <div id="tab-headers" class="a-tabs__header" @scroll="checkScroll">
          <ul>
            <li
              v-for="(tab, index) in tabs"
              :key="tab.title"
              ref="tabHeaders"
              :class="[
                activeTabIndex == index ? 'active' : '',
                +tab?.dataset.length ? '' : 'disabled'
              ]"
              @click="+tab?.dataset.length && changeTab(index)"
            >
              <AText
                class="title"
                :class="+tab?.dataset.length ? '' : 'soon'"
                tag="span"
                :size-lg="{ size: 20, line: 18.8 }"
                :size-sm="{ size: 16, line: 15 }"
              >
                {{ tab.title }}
              </AText>

              <AText
                v-if="!+tab?.dataset.length"
                :class="+tab?.dataset.length ? '' : 'soon'"
                class="soon-text"
                tag="span"
                :size-lg="{ size: 20, line: 18.8 }"
                :size-sm="{ size: 16, line: 15 }"
              >
                Скоро
              </AText>
            </li>
          </ul>
        </div>

        <AText
          v-if="showRightArrow"
          class="scroll-arrow right"
        >
          →
        </AText>
      </div>

      <slot name="filters" />
    </div>

    <div id="active-tab" class="active-tab">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import AText from '~/components/atoms/text/a-text.vue';

interface Props {
  items?: string[],
}

withDefaults(defineProps<Props>(), {
  items: () => [],
});

const tabContainer = ref<HTMLElement |null>(null);
const tabHeaders = ref<HTMLAllCollection | null>(null);
const tabs = ref([]);
const activeTabIndex = ref<number>(0);

const showRightArrow = ref(true);

const checkScroll = (e) => {
  showRightArrow.value = e.target.scrollLeft <= 0;
};

onMounted(() => {
  tabs.value = [...(tabContainer.value as HTMLElement).querySelectorAll('.tab')];
  for (const x of tabs.value) {
    if (x.classList.contains('active')) {
      activeTabIndex.value = tabs.value.indexOf(x);
    }
  }
});
const changeTab = (index: number) => {
  activeTabIndex.value = index;
  for (const x of [...tabs.value, ...tabHeaders.value as HTMLAllCollection]) {
    x.classList.remove('active');
  }
  tabs.value[activeTabIndex.value].classList.add('active');
  tabHeaders.value?.[activeTabIndex.value].classList.add('active');
};

</script>

<style lang="postcss" src="./style.css" />
